import { twMerge } from "tailwind-merge";

export default function AlertGon({ colors = "warning", title = "Text Here" }) {
  return (
    <div
      className={twMerge(
        `bg-${colors}GON-50 text-${colors}GON-500 border-${colors}GON-500`,
        `p-3 flex justify-center border-dashed border rounded-lg`
      )}
    >
      <p className="subheading-medium text-center capitalize">{title}</p>
    </div>
  );
}
