// ModalContext.js
"use client";

import { createContext, useContext } from "react";

export const HeaderContext = createContext();

export default function useHeader() {
  return useContext(HeaderContext);
}
